import React, { PureComponent } from 'react'
import { func, object } from 'prop-types'
import {
  withFormik,
  FieldArray,
  Field
} from 'formik'
import * as Yup from 'yup'
import { css } from 'emotion'

import ErrorMessage from './form-errors'
import {
  mcBlue,
  mcGreen,
  mcRed,
  primaryDark,
  secondaryDark,
  tertiaryDark,
  white
} from 'src/helpers/css-variables'
import { selectDay, selectHour } from 'src/helpers/delivery-fee-ranges'
import { dateOnly, timeOnly, transformToUTCISOString } from 'src/helpers/date'
import { utcToZonedTime } from 'date-fns-tz'

import RemoveIcon from 'src/microcomponents/icon-remove'
import CARET from 'src/assets/caret-white.svg'
import RightArrow from 'src/assets/right-arrow.svg'
import DownArrow from 'src/assets/down-arrow.svg'

const DEFAULT_FORM_DATA = {
  windowCapacity: '',
  dayOfWeek: 0,
  startTime: '09:00',
  endTime: '11:00',
  windowCutOffMinutes: '0',
  newEntry: true
}

class AddDeliveryScheduleForm extends PureComponent {
  constructor (props) {
    super(props)
    // State to track expanded/collapsed status for each day of week
    this.state = {
      expandedDays: selectDay.reduce((acc, day) => {
        // Initialize as expanded
        acc[day.value] = true
        return acc
      }, {})
    }
  }

  // Function to toggle expanded/collapsed state of a day
  toggleDay = (dayValue) => {
    this.setState((prevState) => ({
      expandedDays: {
        ...prevState.expandedDays,
        [dayValue]: !prevState.expandedDays[dayValue]
      }
    }))
  }

  static propTypes = {
    handleBlur: func,
    handleChange: func,
    handleSubmit: func,
    resetForm: func,
    values: object
  }

  handleCancelDeliveryScheduleForm = event => {
    event.preventDefault()
    const { resetForm } = this.props
    resetForm()
  }

  render () {
    const { handleBlur, handleChange, handleSubmit, values } = this.props
    const { expandedDays } = this.state

    return (
      <form onSubmit={handleSubmit} className={DeliveryScheduleForm}>
        <h3 className={formHeader}>Delivery Schedule</h3>
        <FieldArray name='deliverySchedule'>
          {arrayHelpers => (
            <div>
              <div className={addRowPadding}>
                <button
                  className={AddFormRow}
                  type='button'
                  onClick={() => {
                    arrayHelpers.insert(values.deliverySchedule.length, { ...DEFAULT_FORM_DATA })
                  }}
                >
                  + Add new delivery window
                </button>
              </div>

              <div className={SubmitContainer}>
                <button
                  className={CancelButton}
                  onClick={this.handleCancelDeliveryScheduleForm}
                >
                  Cancel
                </button>
                <button type='submit' className={SubmitButton}>
                  Save Changes
                </button>
              </div>
              <div className={DeliveryScheduleRowWrapper}>
                <div className={DeliveryScheduleRowLight}>
                  <div className={ColumnLabel}>Capacity</div>
                  <div className={ColumnLabel}>Day of Week</div>
                  <div className={ColumnLabel}>From</div>
                  <div className={ColumnLabel}>To</div>
                  <div className={ColumnLabel}>Cut Off (min)</div>
                  <div className={ColumnLabelAction}></div>
                </div>

                {values.deliverySchedule.filter(row => row.newEntry === true).map((rowData, rowCount) => {
                  const index = values.deliverySchedule.indexOf(rowData)

                  return (
                    <div
                      className={
                        rowCount % 2 === 0
                          ? DeliveryScheduleRowDark
                          : DeliveryScheduleRowLight
                      }
                      key={`new-${index}`}
                      data-testid='new-row'
                    >
                      {/* Render new record fields */}
                      <div className={InputWrapper}>
                        <Field
                          className={FeeInput}
                          min='0'
                          name={`deliverySchedule.${index}.windowCapacity`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Delivery Window Capacity'
                          type='number'
                        />
                        <ErrorMessage name={`deliverySchedule.${index}.windowCapacity`} />
                      </div>
                      <Field component='select' name={`deliverySchedule.${index}.dayOfWeek`} onChange={handleChange} onBlur={handleBlur} className={DateSelect}>
                        {selectDay.map((dayOption, dayKey) => (
                          <option key={dayKey} value={dayOption.value}>
                            {dayOption.label}
                          </option>
                        ))}
                      </Field>
                      <Field component='select' name={`deliverySchedule.${index}.startTime`} onChange={handleChange} onBlur={handleBlur} className={DateSelect}>
                        {selectHour.map((startTimeOption, startTimeKey) => (
                          <option key={startTimeKey} value={startTimeOption.value}>
                            {startTimeOption.label}
                          </option>
                        ))}
                      </Field>
                      <Field component='select' name={`deliverySchedule.${index}.endTime`} className={DateSelect}>
                        {selectHour.map((endTimeOption, endTimeKey) => (
                          <option key={endTimeKey} value={endTimeOption.value}>
                            {endTimeOption.label}
                          </option>
                        ))}
                      </Field>
                      <div className={InputWrapper}>
                        <Field
                          className={FeeInput}
                          min='0'
                          name={`deliverySchedule.${index}.windowCutOffMinutes`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Cut Off (Min)'
                          type='number'
                        />
                        <ErrorMessage name={`deliverySchedule.${index}.windowCutOffMinutes`} />
                      </div>
                      <button className={DeleteRow} data-testid='delete' type='button' onClick={() => arrayHelpers.remove(index)}>
                        <RemoveIcon />
                      </button>
                    </div>
                  )
                })}

                {/* Loop over each day of the week and display the corresponding delivery windows */}
                {selectDay.map((dayOfWeek) => {
                  const daySchedule = values.deliverySchedule.filter(
                    row => row.dayOfWeek === dayOfWeek.value && row.newEntry !== true
                  )
                  return (
                    <div key={dayOfWeek.value}>
                      {/* Header for each day */}
                      <h4
                        className={`${DayHeader} ${daySchedule.length === 0 ? NoArrow : ''}`}
                        onClick={() => this.toggleDay(dayOfWeek.value)}
                      >
                        {daySchedule.length > 0 &&
                        <img
                        src={expandedDays[dayOfWeek.value] ? DownArrow : RightArrow}
                        className={collapseExpandImage}
                      />}
                        {dayOfWeek.label}
                      </h4>
                      {expandedDays[dayOfWeek.value] && daySchedule.map((rowData, rowCount) => {
                        const index = values.deliverySchedule.indexOf(rowData)

                        return (
                          <div
                            className={
                              rowCount % 2 === 0
                                ? DeliveryScheduleRowDark
                                : DeliveryScheduleRowLight
                            }
                            key={index}
                            data-testid='peak-row'
                          >
                            <div className={InputWrapper}>
                              <Field
                                className={FeeInput}
                                min='0'
                                name={`deliverySchedule.${index}.windowCapacity`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='Delivery Window Capacity'
                                type='number'
                              />
                              <ErrorMessage
                                name={`deliverySchedule.${index}.windowCapacity`}
                              />
                            </div>
                            <Field
                              component='select'
                              name={`deliverySchedule.${index}.dayOfWeek`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={DateSelect}
                            >
                              {selectDay.map((dayOption, dayKey) => (
                                <option key={dayKey} value={dayOption.value}>
                                  {dayOption.label}
                                </option>
                              ))}
                            </Field>
                            <Field
                              component='select'
                              name={`deliverySchedule.${index}.startTime`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={DateSelect}
                            >
                              {selectHour.map((startTimeOption, startTimeKey) => (
                                <option
                                  key={startTimeKey}
                                  value={startTimeOption.value}
                                >
                                  {startTimeOption.label}
                                </option>
                              ))}
                            </Field>
                            <Field
                              component='select'
                              name={`deliverySchedule.${index}.endTime`}
                              row={rowCount}
                              className={DateSelect}
                            >
                              {selectHour.map((endTimeOption, endTimeKey) => (
                                <option key={endTimeKey} value={endTimeOption.value}>
                                  {endTimeOption.label}
                                </option>
                              ))}
                            </Field>
                            <div className={InputWrapper}>
                              <Field
                                className={FeeInput}
                                min='0'
                                name={`deliverySchedule.${index}.windowCutOffMinutes`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='Cut Off (Min)'
                                type='number'
                              />
                              <ErrorMessage
                                name={`deliverySchedule.${index}.windowCutOffMinutes`}
                              />
                            </div>
                            <button
                              className={DeleteRow}
                              data-testid='delete'
                              type='button'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <RemoveIcon />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </FieldArray>
      </form>
    )
  }
}

const DeliveryScheduleValidationSchema = {
  windowCapacity: Yup.number().required('You must enter a capacity'),
  dayOfWeek: Yup.number().required('You must select a day of the week'),
  startTime: Yup.string().required('You must choose a start time'),
  endTime: Yup.string().required('You must choose an end time'),
  windowCutOffMinutes: Yup.number().required('You must enter a cut off time in minutes. 0 = no cut off')
}

const setDeliveryScheduleBody = (deliverySchedule, depotTimeZone) => {
  if (!deliverySchedule || deliverySchedule.length === 0) return []
  const activeDeliveryWindow = deliverySchedule.filter(
    schedule => schedule.active === true
  )
  const mappedDeliverySchedule = activeDeliveryWindow[0].deliveryWindows.map(
    deliveryScheduleData => {
      const startISO = transformToUTCISOString(deliveryScheduleData.timeFrom)
      const endISO = transformToUTCISOString(deliveryScheduleData.timeTo)

      // clip the date off and use today's date to know if it's DST
      let start = `${dateOnly()}T${startISO.split('T')[1]}`
      let end = `${dateOnly()}T${endISO.split('T')[1]}`

      start = utcToZonedTime(start, depotTimeZone).toISOString()
      end = utcToZonedTime(end, depotTimeZone).toISOString()

      const defaultFormData = {
        windowCapacity: deliveryScheduleData.windowCapacity,
        dayOfWeek: deliveryScheduleData.dayOfWeek,
        startTime: timeOnly(start),
        endTime: timeOnly(end),
        windowCutOffMinutes: deliveryScheduleData.windowCutOffMinutes
      }

      return defaultFormData
    }
  )
  return mappedDeliverySchedule
}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const { deliverySchedule, depotTimeZone } = props
    const updatedDeliveryScheduleValues = setDeliveryScheduleBody(
      deliverySchedule,
      depotTimeZone
    )
    if (!updatedDeliveryScheduleValues || updatedDeliveryScheduleValues.length === 0) return { deliverySchedule: [] }
    return { deliverySchedule: updatedDeliveryScheduleValues }
  },
  handleSubmit: (formData, { props }) => {
    props.onSubmitForm(formData)
  },
  handleChange: (formData, { props }) => {
    props.handleChange(formData)
  },
  validationSchema: Yup.object().shape({
    deliverySchedule: Yup.array().of(
      Yup.object().shape(DeliveryScheduleValidationSchema)
    )
  })
})

const DeliveryScheduleForm = css`
  width: 100%;
  background-color: ${secondaryDark};
`

const DeliveryScheduleRowWrapper = css`
  border-radius: 0.4rem;
  border: 0.1rem solid ${primaryDark};
  width: 97%;
  margin: 0 auto;
`

const DeliveryScheduleRowDark = css`
  background-color: ${primaryDark};
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 8.8rem;

  & > select,
  div > input {
    border-radius: 2.4rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: 0.025;
    color: ${white};
    background-color: ${tertiaryDark};
  }
`
const DeliveryScheduleRowLight = css`
  ${DeliveryScheduleRowDark}
  background-color: ${secondaryDark};
`

const InputWrapper = css`
  display: flex;
  flex: 0 0 16.5%;
  flex-direction: column;
`

const FeeInput = css`
  flex: 1;
  max-width: 100%;
`

const ColumnLabel = css`
  color: ${white};
  flex: 0 0 16%;
  max-width: 100%;
  text-align: let;
  padding: 1rem 1.2rem;
`

const ColumnLabelAction = css`
  border: 0;
  padding: 0;
  width: 3.6rem;
  height: 3.6rem;
`

const DateSelect = css`
  border: none;
  appearance: none;
  flex: 0 0 16.5%;
  max-width: 100%;
  padding: 1rem 1.2rem;
  background-image: url(${CARET});
  background-repeat: no-repeat;
  background-position: right 1.2rem top 50%;
`

const AddFormRow = css`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;

  font-size: 1.4rem;
  font-family: system-ui;
  color: ${mcBlue};
`

const DayHeader = css`
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${white};
  background-color: ${tertiaryDark};
  padding: 1.2rem;
  margin-top: 2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${primaryDark};
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
`

const NoArrow = css`
  padding-left: 40px;
`

const DeleteRow = css`
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background: ${mcRed};
  cursor: pointer;

  & > svg {
    width: 2.4rem;
    height: auto;
  }
`

const SubmitContainer = css`
  width: 100%;
  background-color: ${tertiaryDark};
  display: flex;
  justify-content: flex-end;
  padding: 1.2rem 2.5rem;

  & > button {
    outline: none;
    padding: 0.9rem 2.4rem;
    font-family: system-ui;
    font-size: 1.4rem;
    border-radius: 3.4rem;
    letter-spacing: 0.03rem;
    line-height: 1.8rem;
    color: ${white};
    cursor: pointer;
  }
`

const CancelButton = css`
  background-color: transparent;
  border: 0.1rem solid ${white};
  margin-right: 1.2rem;
`

const SubmitButton = css`
  border: none;
  background-color: ${mcGreen};
`

const addRowPadding = css`
  padding: 1.2rem 2.5rem;
`

const formHeader = css`
  color: ${white};
  margin: 2.4rem;
`

const collapseExpandImage = css`
  margin-right: 10px;
  vertical-align: middle;
`

export default formikEnhancer(AddDeliveryScheduleForm)
